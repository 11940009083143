<!-- 海关申请单配置 -->
<template>
  <div class="hoho-page">
    <div class="hoho-container">
      <el-alert
        title="提示：以下内容为 INVOICE 内容，请务必准确填写，一但错误会导致出口许可书不正确，导致无法推送。内容请全部使用英文填写"
        type="warning"
        show-icon
      >
      </el-alert>
      <div class="hoho-form-container">
        <el-card>
          <el-form
            label-width="200px"
            :model="modelForm"
            :rules="rules"
            ref="modelForm"
          >
            <div class="line">
              <div class="line-cell maxwidth">
                <el-form-item label="公司名称 company name" prop="company_name">
                  <el-input
                    v-model="modelForm.company_name"
                    maxlength="100"
                    placeholder="英文公司名称"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="line">
              <div class="line-cell maxwidth">
                <el-form-item label="国家 country" prop="country">
                  <el-input
                    v-model="modelForm.country"
                    maxlength="50"
                    placeholder="英文国家"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="line">
              <div class="line-cell maxwidth">
                <el-form-item label="城市 city" prop="city">
                  <el-input
                    v-model="modelForm.city"
                    maxlength="50"
                    placeholder="英文城市"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="line">
              <div class="line-cell maxwidth">
                <el-form-item label="公司地址 address" prop="address">
                  <el-input
                    v-model="modelForm.address"
                    maxlength="200"
                    placeholder="英文公司地址"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="line">
              <div class="line-cell maxwidth">
                <el-form-item label="邮编 postcode" prop="postcode">
                  <el-input
                    v-model="modelForm.postcode"
                    maxlength="50"
                    placeholder="邮编"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="line">
              <div class="line-cell maxwidth">
                <el-form-item label="电话 TEL" prop="tel">
                  <el-input
                    v-model="modelForm.tel"
                    maxlength="50"
                    placeholder="电话"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="line">
              <div class="line-cell maxwidth">
                <el-form-item label="联系人 contactor" prop="contactor">
                  <el-input
                    v-model="modelForm.contactor"
                    maxlength="50"
                    placeholder="联系人"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="line">
              <div class="line-cell maxwidth">
                <el-form-item label="法人番号" prop="user_code">
                  <el-input
                    v-model="modelForm.user_code"
                    maxlength="50"
                    placeholder="法人番号"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </div>
            </div>
            <div class="common-explain">
              <i class="iconfont icon-icon"></i
              >提示：公司营业藤本，支持最大3张上传。每个文件最大2M
            </div>
            <div class="line auto-height">
              <div class="upload">
                <div class="title">上传公司营业藤本</div>
                <UploadMulti
                  :fileList="modelForm.photo"
                  @SETPICTURE="handelSetPricture"
                ></UploadMulti>
              </div>
            </div>
          </el-form>
        </el-card>
      </div>
    </div>
    <template>
      <div class="hoho-bottom-buts">
        <el-button type="primary" @click="subimtForm">提交</el-button>
      </div>
    </template>
  </div>
</template>

<script>
import { vxRule } from 'utils/validator'
import { GetToken } from 'utils/auth'
import CustomsSettingbill from 'api/customs-setting'
import UploadMulti from 'components/element-upload-multi'
export default {
  components: { UploadMulti },
  name: '',
  data() {
    return {
      labelPosition: 'right',
      modelForm: {
        id: '', // 配置id
        company_name: '', // 公司
        country: '', // 国家
        city: '', // 地区
        address: '', // 地址
        postcode: '', // 邮编
        tel: '', // 电话
        contactor: '', // 联系人
        user_code: '', // 番号
        photo: [] // 营业藤本
      },
      rules: {
        // company_name: vxRule(false, 'English','blur', '请输入英文国家'),
        // country: vxRule(false, 'English', 'blur','请输入英文国家'),
        // city: vxRule(false, 'English', 'blur','只能输入英文'),
        // address: vxRule(false, 'English', 'blur', '只能输入英文'),
        // postcode: vxRule(false, 'English', 'blur', '只能输入英文'),
        // tel: vxRule(false, 'English', 'blur', '只能输入英文'),
        // contactor: vxRule(false, 'English', 'blur', '只能输入英文'),
        // user_code: vxRule(false, 'English', 'blur', '只能输入英文')
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getCustomsSetting()
  },
  mounted() {},
  destroyed() {},
  methods: {
    // 附件
    handelSetPricture(data) {
      this.modelForm.photo = data
    },
    // 获取分类
    getCustomsSetting() {
      CustomsSettingbill.getCustomsSetting({
        token: GetToken()
      }).then((res) => {
        console.log(res)
        if (res.success) {
          if (res.record) {
            this.modelForm = res.record
            this.modelForm.photo = JSON.parse(res.record.phone) || []
            console.log('modelForm', this.modelForm)
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 提交商品
    subimtForm() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          CustomsSettingbill.customsSetting({
            token: GetToken(),
            ...this.modelForm,
            phone: JSON.stringify(this.modelForm.photo)
          }).then((res) => {
            console.log(res)
            if (res.success) {
              this.$message.success('提交成功')
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hoho-page {
  height: 100vh;
  overflow: hidden;
}
.hoho-container {
  overflow-y: auto;
  padding: 10px;
  flex: 1;
}
.common-explain {
  display: flex;
  align-items: center;
  color: rgb(229, 161, 71);
  margin-bottom: 5px;
  font-size: 12px;
  .iconfont {
    margin-right: 2px;
    font-size: 14px;
  }
}
.common-explain.nobott {
  margin-bottom: 0;
}
.line {
  display: flex;
  align-content: center;
  height: 58px;
  .qrcode {
    height: 36px;
  }
  .line-cell.goodscode {
    width: 340px;
  }
  .line-cell.qrcode {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    .explain {
      font-size: 10px;
      transform: scale(0.8);
      margin-top: -2px;
    }
  }
  .line-cell.nospace {
    ::v-deep {
      .el-form-item__content {
        margin-left: 10px !important;
      }
    }
  }
  .line-cell.goodsname {
    width: 470px;
    padding-top: 5px;
    ::v-deep {
      .el-select {
        width: 100% !important;
      }
    }
  }
  .line-translate {
    height: 41px;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .line-cell.translate {
    width: 490px;
    background: rgb(253, 246, 237);
    padding-right: 20px;
    padding-top: 5px;
    margin-left: 10px;
  }
  .line-cell.maxwidth {
    width: 600px;
  }
  .upload {
    display: flex;
    margin: 22px 0;
    .title {
      width: 190px;
      text-align: right;
      margin-right: 10px;
      color: rgb(96, 98, 102);
    }
  }
  .line-cell.engoodsname {
    width: 480px;
  }
}
.line.auto-height {
  height: auto;
}
</style>
