<template>
  <div>
    <el-upload
      action="https://jsonplaceholder.typicode.com/posts/"
      list-type="picture-card"
      :multiple="multiple"
      :accept="accept"
      :limit="limit"
      :file-list="fileList"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-change="beforeAvatarUpload"
      :on-exceed="handleExceed"
      :http-request="handleUpload"
    >
      <i class="el-icon-plus"></i>
      <div slot="tip" class="el-upload__tip">{{ explain }}</div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import Common from 'api/common'
export default {
  components: {},
  name: '',
  props: {
    limit: { type: Number, default: 3 },
    multiple: { type: Boolean, default: false },
    accept: { type: String, default: 'image/*' },
    explain: { type: String, default: '只能上传jpeg,jpg,png文件，且不超过2M' },
    fileList: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return { dialogImageUrl: '', dialogVisible: false }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  destroyed() {},
  methods: {
    beforeAvatarUpload(file) {
      // console.log('before avatar upload', file)
      const is1M = file.size / 1024 / 1024 < 2 // 限制小于5M
      const jpg = file.raw.type === 'image/jpg'
      const png = file.raw.type === 'image/png'
      const jpeg = file.raw.type === 'image/jpeg'

      if (!jpg && !png && !jpeg) {
        this.$message.error('上传文件只能是 JPG 、PNG、JPG格式!')
      }
      if (!is1M) {
        this.$message.error('图片大小不可超过2MB')
      }
      return is1M && (jpg || png || jpeg)
    },
    handleExceed(files, fileList) {
      this.$message.warning(`最多支持上传 ${this.limit} 个文件`)
    },
    // 上传图片
    handleUpload(data) {
      // console.log('handle upload:', res)
      var file = data.file
      Common.uploadImage({ file }).then((res) => {
        console.log(this.fileList,'this.fileList')
        if (res.data.state) {
          this.fileList.push({
            name: data.file.name,
            url: res.data.data
          })
          this.$emit('SETPICTURE', this.fileList)
        }
      })
    },
    handleRemove(file, fileList) {
      this.$emit('SETPICTURE', fileList)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
