import { netpost } from '../plugins/request'

export default {
  // 获取海关配置
  getCustomsSetting(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetCustomsSetting`, params)
  },
  // 更新或新增配置
  customsSetting(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/CustomsSetting`, params)
  },
}
